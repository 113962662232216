<template>
  
  <router-view></router-view>
  <!-- <router-view name="nav"></router-view>
  <router-view name="content"></router-view>
  <router-view name="footer"></router-view> -->

  <!-- <MainPage v-if="false"/> -->
  <!-- <WalkPage /> -->
</template>

<script>
// import MainPage from '@/pages/rent/MainPage.vue'
// import WalkPage from '@/pages/walk/WalkPage.vue'

export default {
  name: 'App',
  components: {
    // MainPage,
    // WalkPage
  }
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
}
</style>
