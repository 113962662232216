import {
    CalendarIcon,
    AdjustmentsIcon,
    ViewBoardsIcon,
    BriefcaseIcon,
    //   PencilAltIcon,
    CollectionIcon,
    ChatAlt2Icon,
    UserGroupIcon
} from '@heroicons/vue/outline'

export const Globals = {
    namespaced: true,
    state: () => ({
        apiUrl: 'https://api.spbsupboard.ru/',
        apiUrlWs: 'wss://api.spbsupboard.ru/',

        sidebarNavigation: [
            { name: "Dashboard", to: "/admin", icon: ViewBoardsIcon, current: false },
            { name: "Календарь", to: "/admin/calendar", icon: CalendarIcon, current: false },
            { name: "Заказы", to: "/admin/orders", icon: BriefcaseIcon, current: false },
            { name: "Клиенты", to: "/admin/clients", icon: UserGroupIcon, current: false },
            { name: "Чат", to: "/admin/chat", icon: ChatAlt2Icon, current: false, children: [] },
            { name: "Сапборды", to: "/admin/sups", icon: CollectionIcon, current: false },
            { name: "Настройки", to: "/admin/setting", icon: AdjustmentsIcon, current: false },
            // { name: "Drafts", to: "#", icon: PencilAltIcon, current: false },
        ],

    }),
    actions: {
        setSidebarNavigation({ state }, namePage) {
            state.sidebarNavigation.forEach((item) => {

                if (item.name === namePage) {
                    item.current = true
                } else {
                    item.current = false
                }

            })
        }
    },
}