<template>
  <ChatRoom :room="room" />
  <!-- :room="room" -->
</template>

<script>
import { mapState } from "vuex";
// import { watch, ref } from "vue";
// import { io } from "socket.io-client";
// import ConnectionManager from "@/admin/components/ConnectionManager.vue";
// import ConnectionState from "@/admin/components/ConnectionState.vue";
// import MyForm from "@/admin/components/MyForm.vue";
import ChatRoom from "@/admin/components/ChatRoom.vue";

export default {
  components: {
    // ConnectionManager,
    // ConnectionState,
    // MyForm,
    ChatRoom,
    // ChatMsgLeft,
    // ChatMsgRigth,
  },
  data() {
    return {};
  },
  // watch: {
  //   "MsgsModule.msgs": {
  //     handler(newVal) {
  //       this.msgs = newVal;
  //       this.scrollToElement();
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    // ...mapActions("ProfilesModule", ["getProfile"]),
    // scrollToElement() {
    // this.$refs.targetRef.scrollIntoView({
    //   behavior: "smooth",
    // });
    // },
  },
  computed: {
    ...mapState(["MsgsModule", "ProfilesModule"]),
    room() {
      return this.$route.query.room
        ? this.$route.query.room
        : this.ProfilesModule.profile.id;
      //   return this.MsgsModule.room;
      //   // if (this.MsgsModule.room !== "" && this.uid !== undefined) {
      //   //   return this.MsgsModule.room;
      //   // } else {
      //   //   return this.uid;
      //   // }
    },
    // uid() {
    //   return this.ProfilesModule.profile.id;
    // },
  },
  watch: {
    // uid(newVal) {
    //   this.MsgsModule.room = newVal;
    // },
  },

  mounted() {
    // this.getProfile().then(() => {
    //   if ((this.MsgsModule.room == "") | (this.MsgsModule.room == null)) {
    //     this.MsgsModule.room = this.ProfilesModule.profile.id;
    //   }
    // });
    // if ((this.MsgsModule.room == "") | (this.MsgsModule.room == null)) {
    //   this.MsgsModule.room = this.ProfilesModule.profile.id;
    // }
    // this.MsgsModule.room = "81";
    // this.$nextTick(function () {
    // this.msgs = this.MsgsModule.msgs;
    // this.scrollToElement();
    // });
  },
};
</script>
