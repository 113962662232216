<!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<!-- Структура входных параметров:
      listItem =  options: [
                        {
                            name: 'Не выбрано',
                            value: 1
                        },
                        ...
                    ],
      item = {
                 name: 'Не выбрано',
                 value: 1
             }
             
      Структура входных параметров:
      selectedItem = { name: .., value: ..} 
 -->
<template>
  <Combobox as="div" v-model="selectedItem">
    <!-- <ComboboxLabel class="block text-sm font-medium text-gray-700"
      >Assigned to</ComboboxLabel
    > -->
    <div class="relative mt-1">
      <ComboboxInput
        class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
        @change="query = $event.target.value"
        :display-value="(person) => person.name"
      />
      <ComboboxButton
        class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
      >
        <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </ComboboxButton>

      <ComboboxOptions
        v-if="filteredItem.length > 0"
        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      >
        <ComboboxOption
          v-for="person in filteredItem"
          :key="person.id"
          :value="person"
          as="template"
          v-slot="{ active, selected }"
        >
          <li
            :class="[
              'relative cursor-default select-none py-2 pl-3 pr-9',
              active ? 'bg-indigo-600 text-white' : 'text-gray-900',
            ]"
          >
            <span :class="['block truncate', selected && 'font-semibold']">
              {{ person.name }}
            </span>

            <span
              v-if="selected"
              :class="[
                'absolute inset-y-0 right-0 flex items-center pr-4',
                active ? 'text-white' : 'text-indigo-600',
              ]"
            >
              <CheckIcon class="h-5 w-5" aria-hidden="true" />
            </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>
    </div>
  </Combobox>
</template>

<script>
import { computed, ref, watch } from "vue";
import { CheckIcon, SelectorIcon } from "@heroicons/vue/solid";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  // ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/vue";

export default {
  components: {
    CheckIcon,
    Combobox,
    ComboboxButton,
    ComboboxInput,
    // ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
    SelectorIcon,
  },
  props: {
    listItem: { default: [] },
    item: { default: {} },
  },
  // emits: ["select"],
  setup(props, { emit }) {
    const query = ref("");
    const selectedItem = ref(props.item);
    const filteredItem = computed(() =>
      query.value === ""
        ? props.listItem
        : props.listItem.filter((person) => {
            return person.name.toLowerCase().includes(query.value.toLowerCase());
          })
    );
    watch(selectedItem, (selectedItem) => {
      //  selectedItem = {value: .., name: ..}
      // emit("select", selectedItem);
      emit("update:item", selectedItem);
    });

    return {
      query,
      selectedItem,
      filteredItem,
    };
  },
};
</script>
